import requestJSON from '@/utils/requestJSON';

/**
 * @description 加载JSON文件
 * @param { string } url
 */
export function getJSON(url) {
  return requestJSON({
    url
  });
}
