<template>
  <div>
    <ksnHeader item="aboutus"></ksnHeader>

    <div class="page-title-area bg-1">
      <div class="container">
        <div class="page-title-content">
          <h2>关于我们</h2>
          <ul>
            <li>
              <a :href="$assetPrefix">首页</a>
            </li>
            <li class="active">关于我们</li>
          </ul>
        </div>
      </div>
    </div>

    <section class="expertise-area pt-100 pb-70">
      <div class="container">
        <div class="section-title">
          <h2>AI创新产品 · 技术智慧生命</h2>
          <p>
            中国第一家专注于全方位AI智能肺癌早筛早诊的科技公司，致力于打造集AI影像、基因检测、肺癌云数据为一体的综合平台
          </p>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="single-expertises">
              <i class="flaticon-tick"></i>
              <h3>坚持技术创新作为底层驱动，保持研发高效性</h3>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="single-expertises">
              <i class="flaticon-pyramid"></i>
              <h3>坚持人工智能算法创新推动，确保技术领先性</h3>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
            <div class="single-expertises">
              <i class="flaticon-nutrition"></i>
              <h3>坚持临床痛点指引产品研发，保证产品可用性</h3>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-us-area pt-100 pb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="about-img-content">
              <div class="about-img-1">
                <img
                  :src="$assetPrefix + '/static/images/about/about-img-9.jpg'"
                  alt="Image"
                />
                <div class="about-img-2">
                  <img
                    :src="
                      $assetPrefix + '/static/images/about/about-img-11.jpg'
                    "
                    alt="Image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-content">
              <span>元范式</span>
              <h2>关于我们</h2>
              <!-- <h4>打造集AI影像、基因检测、肺癌云数据为一体的综合平台</h4> -->
              <p>
                元范式（福州）科技有限公司成立于2021年，总部位于福建福州，是一家专注于开发及商业化创新癌症筛查产品的生物科技公司，汇聚众多人工智能及医学研究领域专家，致力于成为全国肺癌AI辅助早筛早诊领域的领军品牌。
                <br />
                <br />公司云集人工智能、互联网云计算、医学影像等多个领域专业人才，公司核心团队由医疗领域资深从业人员和来自国内外知名院校的博士和高级科研人员组成。牵头人物张安国，澳门大学Research
                Fellow，福建省高层次人才，福建省青年工科类人才，珠海澳大科技研究院——凌烟阁芯片科技联合实验室AI研究员。长期从事类脑神经网络、深度学习相关的学术研究和技术开发工作。曾任锐捷网络股份有限公司高级算法工程师、锐捷网络股份有限公司创新专家委员会应用软件技术专家，主要从事人工智能新技术&新产品预研工作，负责或主要参与过智能通信网络、工业智能制造相关的项目研发及核心技术攻关。在人工智能领域，于CVPR，IEEE
                Transactions on Neural Networks and Learning Systems, IEEE
                Transactions on Cognitive and Developmental
                Systems,等国际顶级学术会议和期刊上发表20余篇论文，并获得4项发明专利授权，4项软件著作权授权。张安国博士带领的研发团队，由重庆大学博士周勇城，高级工程师何武德、严火荣等重要骨干组成。
                <br />
                <br />公司致力于打造集AI影像、基因检测、肺癌云数据为一体的综合平台，以肺癌早筛为策略重点，针对肺癌早筛无法获得高准确率的行业痛点，通过液体活检方式，提高肺癌早筛的特异性和敏感性。专业从事医学早期检测，我们坚持掌握前沿核心生物技术,研发保持高投入，确保产品的领先性。
                <br />
                <br />通过卷积神经网络智能算法的影像组学联合液体活检甲基化的智能分析,达到对肺结节良恶性的判断，基于人工智能深度记忆学习的AI和生物医学相结合建立三维CT
                影像神经网络分析模型+DNA甲基化芯片的复合算法，通过前期临床研究已经筛选出外周血肺癌特异性DNA甲基化特征片段的研发平台，已经具备血液ctDNA甲基化测序的基因芯片设计的基本条件。
                <br />
                <br />以诊断特征靶点专利申请，AI软件和算法专利申请，肺癌基因芯片试剂盒上市为技术目标，市场占有率20%，市场覆盖率3%为市场目标，通过生物技术和AI技术，改善人类健康。“做好产品，创新服务”是元范式生物的永远追求，竭力提供—流的医学检验产品和良好的技术服务，以先进的技术资源为依托,优秀的团队为基础,提供更加快速、精准的检测方法，辅助临床制定有效治疗方案，提高患者的治疗效果，为人类健康奉献，减少社会医疗成本。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="about-shape-img">
        <img
          :src="$assetPrefix + '/static/images/about-shape.png'"
          alt="Image"
        />
      </div>
    </section>

    <!-- <section
      class="team-area team-page-style pt-100 pb-70"
      v-if="expertList.length > 0"
    >
      <div class="container">
        <div class="section-title">
          <span>元范式</span>
          <h2>专家团队</h2>
        </div>
        <div class="row">
          <div
            class="col-lg-4 col-md-6"
            v-for="(item, index) in expertList"
            v-bind:key="index"
          >
            <div class="single-team">
              <div class="team-img">
                <img :src="item.image" alt="Image" />
              </div>
              <div class="team-content">
                <h3>{{ item.name }}</h3>
                <span>
                  <div v-html="item.title"></div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section
      class="team-area team-page-style pt-100 pb-70"
      v-if="adviserList.length > 0"
    >
      <div class="container">
        <div class="section-title">
          <span>元范式</span>
          <h2>技术顾问</h2>
        </div>
        <div class="row">
          <div
            class="col-lg-4 col-md-6"
            v-for="(item, index) in adviserList"
            v-bind:key="index"
          >
            <div class="single-team">
              <div class="team-img">
                <img :src="item.image" alt="Image" />
              </div>
              <div class="team-content">
                <h3>{{ item.name }}</h3>
                <span>
                  <div v-html="item.title"></div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <ksnFooter></ksnFooter>
  </div>
</template>

<script>
import ksnHeader from "./header";
import ksnFooter from "./footer";
import { getJSON } from "@/api/getJSON";

export default {
  name: "About",
  components: { ksnHeader, ksnFooter },
  created() {
    let jsonURL = this.$assetPrefix + "/static/config/team.json";
    getJSON(jsonURL).then((team) => {
      this.adviserList = team["adviser"];
      this.expertList = team["expert"];
      this.adviserList.forEach((item, index) => {
        item.image = this.$assetPrefix + "/static/config/team/" + item.image;
        item.title = item.title.replaceAll("。", "<br />");
      });

      this.expertList.forEach((item, index) => {
        item.image = this.$assetPrefix + "/static/config/team/" + item.image;
        item.title = item.title.replaceAll("。", "<br />");
      });
    });
  },
  mounted() {},
  data() {
    return {
      adviserList: [],
      expertList: [],
    };
  },
};
</script>
