import Vue from "vue";
import App from "./App.vue";

import router from "./router.js";

import team from "../public/static/config/team.json";

Vue.config.productionTip = false;

let adviserList = [];
let expertList = [];
try {
  adviserList = team["adviser"];
  expertList = team["expert"];

  adviserList.forEach((item, index) => {
    item.image = "/static/config/team/" + item.image;
    item.title = item.title.replaceAll("。", "<br />");
  });

  expertList.forEach((item, index) => {
    item.image = "/static/config/team/" + item.image;
    item.title = item.title.replaceAll("。", "<br />");
  });
} catch (err) {
  console.log("Init Page", err);
}

Vue.prototype.$adviserList = adviserList;
Vue.prototype.$expertList = expertList;

Vue.prototype.$assetPrefix = "";
// Vue.prototype.$assetPrefix = "/home";

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
