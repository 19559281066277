<template>
  <div>
    <ksnHeader item="index"></ksnHeader>

    <section class="hero-slider-area">
      <div class="hero-slider owl-carousel owl-theme">
        <div class="hero-slider-item bg-1">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="hero-slider-content">
                  <h1>让医疗更智能</h1>
                  <p>专注于肿瘤领域的人工智能高科技创新企业</p>
                  <div class="hero-slider-btn">
                    <router-link :to="'services'" class="default-btn"
                      >查看更多</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hero-slider-item bg-2">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="hero-slider-content">
                  <h1>AI医学影像新视界</h1>
                  <p>致力于开展医学影像深度学习研发与应用</p>
                  <div class="hero-slider-btn">
                    <router-link :to="'services'" class="default-btn"
                      >查看更多</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hero-slider-item bg-3">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="hero-slider-content">
                  <h1>AI医疗解决方案</h1>
                  <p>致力于打造国际领先的AI医疗解决方案</p>
                  <div class="hero-slider-btn">
                    <router-link :to="'solution'" class="default-btn"
                      >查看更多</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="expertise-area pt-100 pb-70">
      <div class="container">
        <div class="section-title">
          <h2>解决方案</h2>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="single-expertise">
              <img
                :src="$assetPrefix + '/static/images/expertise/expertise-4.jpg'"
                alt="Image"
              />
              <div class="expertise-title">
                <h3>专家远程阅片平台</h3>
              </div>
              <div class="expertise-content">
                <h3>专家远程阅片平台</h3>
                <p>
                  提供数十位国内知名影像科专家，遇到疑似恶性病例时只需上传影像文件便可以得
                  到Al+专家的咨询，并在短时间内快速得到影像建议，解决优质医疗资源分布不
                  均衡的问题。
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="single-expertise">
              <img
                :src="$assetPrefix + '/static/images/expertise/expertise-5.jpg'"
                alt="Image"
              />
              <div class="expertise-title">
                <h3>肺结节智能识别系统</h3>
              </div>
              <div class="expertise-content">
                <h3>肺结节智能识别系统</h3>
                <p>
                  来自于国内知名专家标注的海量肺部 CT
                  数据，使用人工智能卷积神经网络深度学习技术，通过对可疑结节精准定位，并进行良恶性判别及浸润程度预测，为医生发现肺癌提供全方位的辅助，从而提高医生诊断效率和准确率。
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
            <div class="single-expertise">
              <img
                :src="$assetPrefix + '/static/images/expertise/expertise-6.jpg'"
                alt="Image"
              />
              <div class="expertise-title">
                <h3>全方位肺结节智能化评估系统</h3>
              </div>
              <div class="expertise-content">
                <h3>全方位肺结节智能化评估系统</h3>
                <p>
                  人工智能卷积神经网络深度学习技术联合循环肿瘤细胞基因甲基化测序技术以及突变基因检测技术，对肺癌术前恶性判断，术后药物疗效和生存预测等多模块化全方位肺结节智能化评估系统。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-us-area pb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="about-img-content">
              <div class="about-img-1">
                <img
                  :src="$assetPrefix + '/static/images/about/about-img-6.jpg'"
                  alt="Image"
                />
                <div class="about-img-2">
                  <img
                    :src="
                      $assetPrefix + '/static/images/about/about-img-10.jpg'
                    "
                    width="500px"
                    alt="Image"
                  />
                </div>
              </div>
              <!-- <h3>
                <span>25</span> Years of Experience
              </h3>-->
              <!-- <a href="https://www.youtube.com/watch?v=yH3CPw9HLY0" class="popup-youtube video">
                <div class="video-btn">
                  <i class="flaticon-play-button-1"></i>
                </div>
              </a>-->
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-content">
              <span>元范式</span>
              <h2>关于我们</h2>
              <p>
                元范式（福州）科技有限公司，成立于2021年，是一家专注于开发及商业化创新癌症筛查产品的生物科技公司，致力于通过突破性的人工智能技术及自主研发的核心算法为国内外医疗服务机构提供人工智能医疗解决方案。以肺癌早筛为策略重点，针对肺癌早筛无法获得高准确率的行业痛点，通过液体活检方式，提高肺癌早筛的特异性和敏感性。专业从事医学早期检测，我们坚持掌握前沿核心生物技术,研发保持高投入，确保产品的领先性。
                <br />
                <br />公司云集人工智能、互联网云计算、医学影像等多个领域专业人才，公司核心团队由医疗领域资深从业人员和来自国内外知名院校的博士和高级科研人员组成。牵头人物张安国，澳门大学Research
                Fellow，福建省高层次人才，福建省青年工科类人才，珠海澳大科技研究院——凌烟阁芯片科技联合实验室AI研究员。长期从事类脑神经网络、深度学习相关的学术研究和技术开发工作。曾任锐捷网络股份有限公司高级算法工程师、锐捷网络股份有限公司创新专家委员会应用软件技术专家，主要从事人工智能新技术&新产品预研工作，负责或主要参与过智能通信网络、工业智能制造相关的项目研发及核心技术攻关。在人工智能领域，于CVPR，IEEE
                Transactions on Neural Networks and Learning Systems, IEEE
                Transactions on Cognitive and Developmental
                Systems,等国际顶级学术会议和期刊上发表20余篇论文，并获得4项发明专利授权，4项软件著作权授权。张安国博士带领的研发团队，由重庆大学博士周勇城，高级工程师何武德、严火荣等重要骨干组成。
              </p>

              <div class="hero-slider-btn">
                <router-link :to="'aboutus'" class="default-btn"
                  >查看更多</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-shape-img">
        <img
          :src="$assetPrefix + '/static/images/about-shape.png'"
          alt="Image"
        />
      </div>
    </section>

    <!-- <section class="team-area team-page-style pt-100 pb-70">
      <div class="container">
        <div class="section-title">
          <span>元范式</span>
          <h2>专家团队</h2>
        </div>
        <div class="row">
          <div
            class="col-lg-4 col-md-6"
            v-for="(item, index) in expertList"
            v-bind:key="index"
          >
            <div class="single-team">
              <div class="team-img">
                <img :src="item.image" alt="Image" />
              </div>
              <div class="team-content">
                <h3>{{ item.name }}</h3>
                <span>
                  <div v-html="item.title"></div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <ksnFooter></ksnFooter>
  </div>
</template>

<script>
import custom_ksn from "../../public/static/js/custom-ksn.js";
import ksnHeader from "./header";
import ksnFooter from "./footer";
import { getJSON } from "@/api/getJSON";

export default {
  name: "Index",
  components: { ksnHeader, ksnFooter },
  created() {},
  mounted() {
    setTimeout(() => {
      custom_ksn();
    }, 0);
    let jsonURL = this.$assetPrefix + "/static/config/team.json";
    getJSON(jsonURL).then((team) => {
      this.adviserList = team["adviser"];
      this.expertList = team["expert"];
      this.adviserList.forEach((item, index) => {
        item.image = this.$assetPrefix + "/static/config/team/" + item.image;
        item.title = item.title.replaceAll("。", "<br />");
      });

      this.expertList.forEach((item, index) => {
        item.image = this.$assetPrefix + "/static/config/team/" + item.image;
        item.title = item.title.replaceAll("。", "<br />");
      });
    });
  },
  data() {
    return {
      adviserList: [],
      expertList: [],
    };
  },
};
</script>
