<template>
  <div>
    <ksnHeader item="services"></ksnHeader>

    <div class="page-title-area bg-11">
      <div class="container">
        <div class="page-title-content">
          <h2>产品介绍</h2>
          <ul>
            <li>
              <a :href="$assetPrefix">首页</a>
            </li>
            <li class="active">产品介绍</li>
          </ul>
        </div>
      </div>
    </div>

    <section
      class="healthy-life-area healthy-life-area-style-three bg-color-three ptb-100"
    >
      <div class="container">
        <div class="section-title">
          <span>AI · 元范式</span>
          <h2>开拓医学影像新视界</h2>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-5">
            <div class="healthy-life-content">
              <h2>肺癌早筛系统</h2>
              <p>
                权威院士团队研发，深厚人工能智能技术积累，肺结节精准搜索和定位，高性能稳定良恶性判断，高效率肺结节识别，快速判断辅助医师诊断，上万次运行和演算，运行安全高效稳定。
              </p>
              <h3>产品优势:</h3>
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <ul class="mb-need">
                    <li>算法精准</li>
                    <li>准确率高</li>
                    <li>敏感度高</li>
                  </ul>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <ul>
                    <li>快速可靠</li>
                    <li>应用广泛</li>
                    <li>迭代更新</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="healthy-life-img-3">
              <div class="healthy-life-content">
                <h3>核心功能:</h3>
              </div>
              <img
                :src="$assetPrefix + '/static/images/product/product-10.png'"
                alt="Image"
              />
            </div>
          </div>
        </div>

        <div class="row align-items-center" style="margin-top: 50px">
          <div class="col-lg-6">
            <div class="healthy-life-img-3">
              <img
                :src="$assetPrefix + '/static/images/product/product-13.jpg'"
                alt="Image"
              />
            </div>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-5">
            <div class="healthy-life-content">
              <h2>斐智检</h2>
              <p>
                权威院士团队和医学专家团队，多年潜心研究开发的多模块化全方位人工智能算法和基因检测联合技术，全方位对肿瘤的筛选，用药，预后预测等多维度全面评估系统。
              </p>
              <h3>核心功能:</h3>
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <ul class="mb-need">
                    <li>甲基化突变评估和临床用药指导。</li>
                    <li>EGFR突变评估和靶向药物用药指导。</li>
                  </ul>
                </div>
              </div>
              <br />
              <h3>产品优势:</h3>
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <ul class="mb-need">
                    <li>敏感: 早期即可发现肿瘤</li>
                    <li>科学: 人工智能算法和基因检测联合</li>
                  </ul>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <ul>
                    <li>精准: 算法精准，基因检测精准</li>
                    <li>安全: 海量要本学习和验证</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <ksnFooter></ksnFooter>
  </div>
</template>

<script>
import ksnHeader from "./header";
import ksnFooter from "./footer";

export default {
  name: "Services",
  components: { ksnHeader, ksnFooter },
  mounted() {
    setTimeout(() => {}, 0);
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
    };
  },
};
</script>
