<template>
  <div>
    <ksnHeader item="solution"></ksnHeader>

    <div class="page-title-area bg-11">
      <div class="container">
        <div class="page-title-content">
          <h2>解决方案</h2>
          <ul>
            <li>
              <a :href="$assetPrefix">首页</a>
            </li>
            <li class="active">解决方案</li>
          </ul>
        </div>
      </div>
    </div>

    <section class="healthy-life-area services-page-style pt-100 pb-70">
      <div class="container">
        <div class="section-title">
          <span>AI · 元范式</span>
          <h2>国际领先的AI医疗解决方案</h2>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="single-what-we-offer">
              <img
                :src="$assetPrefix + '/static/images/expertise/expertise-8.jpg'"
                alt="Image"
              />
              <div class="what-we-offer-content">
                <h3>专家远程阅片平台</h3>
                <p>
                  提供数十位国内知名影像科专家，遇到疑似恶性病例时只需上传影像文件便可以得
                  到Al+专家的咨询，并在短时间内快速得到影像建议，解决优质医疗资源分布不
                  均衡的问题。
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="single-what-we-offer">
              <img
                :src="$assetPrefix + '/static/images/expertise/expertise-9.jpg'"
                alt="Image"
              />
              <div class="what-we-offer-content">
                <h3>肺结节智能识别系统</h3>
                <p>
                  来自于国内知名专家标注的海量肺部 CT
                  数据，使用人工智能卷积神经网络深度学习技术，通过对可疑结节精准定位，并进行良恶性判别及浸润程度预测，为医生发现肺癌提供全方位的辅助，从而提高医生诊断效率和准确率。
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="single-what-we-offer">
              <img
                :src="
                  $assetPrefix + '/static/images/expertise/expertise-10.jpg'
                "
                alt="Image"
              />
              <div class="what-we-offer-content">
                <h3>全方位肺结节智能化评估系统</h3>
                <p>
                  人工智能卷积神经网络深度学习技术联合循环肿瘤细胞基因甲基化测序技术以及突变基因检测技术，对肺癌术前恶性判断，术后药物疗效和生存预测等多模块化全方位肺结节智能化评估系统。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ksnFooter></ksnFooter>
  </div>
</template>

<script>
import ksnHeader from "./header";
import ksnFooter from "./footer";

export default {
  name: "Services",
  components: { ksnHeader, ksnFooter },
  mounted() {
    setTimeout(() => {}, 0);
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
    };
  },
};
</script>
