<template>
  <div>
    <footer class="footer-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-24 col-md-24">
            <div class="single-footer-widget">
              <h3>联系方式</h3>
              <ul class="address">
                <li class="location">
                  <i class="bx bx-location-plus"></i>
                  福州福州仓山区浦上大道200号2#1911
                </li>
                <li>
                  <i class="bx bx-phone"></i>
                  <a href="tel:18850712420">18850712420</a>
                </li>
                <li>
                  <i class="bx bx-envelope"></i>
                  metafun@126.com
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <div class="copy-right-area">
      <div class="container">
        <p>
          Copyright @2021 元范式.
          <a href="https://envytheme.com/" target="_blank"></a>
        </p>
      </div>
    </div>

    <!-- <div class="go-top">
      <i class="bx bx-chevrons-up"></i>
      <i class="bx bx-chevrons-up"></i>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Footer",
  mounted() {},
  data() {
    return {};
  },
};
</script>
