<template>
  <div>
    <ksnHeader item="news"></ksnHeader>

    <div class="page-title-area bg-14">
      <div class="container">
        <div class="page-title-content">
          <h2>公司资讯</h2>
          <ul>
            <li>
              <a :href="$assetPrefix">首页</a>
            </li>
            <li class="active">公司资讯</li>
          </ul>
        </div>
      </div>
    </div>

    <section class="news-list-area ptb-100" v-if="newsList.length > 0">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div
                class="col-lg-12 col-md-6"
                v-for="(item, index) in newsList"
                v-bind:key="index"
              >
                <div class="single-news">
                  <img :src="item.image" alt="Image" />
                  <div class="news-content">
                    <ul>
                      <li>
                        <i class="bx bxs-user"></i>
                        {{ item.author }}
                      </li>
                      <li>
                        <i class="bx bxs-calendar"></i>
                        <span>{{ item.time }}</span>
                      </li>
                    </ul>
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.content }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <ksnFooter></ksnFooter>
  </div>
</template>

<script>
import ksnHeader from "./header";
import ksnFooter from "./footer";
import { getJSON } from "@/api/getJSON";

export default {
  name: "News",
  components: { ksnHeader, ksnFooter },
  created() {
    let jsonURL = this.$assetPrefix + "/static/config/news.json";
    getJSON(jsonURL).then((news) => {
      this.newsList = news["snapshot"];
      this.newsList.forEach((item, index) => {
        item.image = this.$assetPrefix + "/static/config/news/" + item.image;
      });
    });
  },
  mounted() {},
  data() {
    return {
      newsList: [],
    };
  },
};
</script>
