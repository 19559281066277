import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from './components/index.vue'
import News from './components/news.vue'
import About from './components/about.vue'
import Solution from './components/solution.vue'
import Contact from './components/contact.vue'
import Services from './components/services.vue'

Vue.use(VueRouter);

const routes = [{
    path: '/index',
    component: Index
},{
    path: '/news',
    component: News
},{
    path: '/contactus',
    component: Contact
},{
    path: '/aboutus',
    component: About
},{
    path: '/solution',
    component: Solution
},{
    path: '/services',
    component: Services
},{
    path: '/',
    component: Index
}];

const router = new VueRouter({
    routes
});

export default router