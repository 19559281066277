<template>
  <div>
    <header class="header-area">
      <div class="navbar-area">
        <div class="mobile-nav">
          <div class="container">
            <div class="mobile-menu">
              <div class="logo">
                <a :href="$assetPrefix">
                  <img
                    :src="$assetPrefix + '/static/images/logo.png'"
                    alt="logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="desktop-nav">
          <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
              <a class="navbar-brand" :href="$assetPrefix">
                <img
                  :src="$assetPrefix + '/static/images/logo.png'"
                  alt="logo"
                />
              </a>
              <div class="collapse navbar-collapse mean-menu">
                <ul class="navbar-nav m-auto">
                  <li
                    class="nav-item"
                    v-for="(item, index) in navJson"
                    v-bind:key="index"
                  >
                    <div style="display: flex">
                      <a
                        :href="item.href"
                        :class="item.active ? 'nav-link active' : 'nav-link'"
                        :target="item.name != 'MLab' ? '_self' : '_blank'"
                      >
                        <!-- <img
                          v-if="item.name == '肺诺克'"
                          style="height: 25px"
                          :src="$assetPrefix + '/static/images/fnk.png'"
                        /> -->
                        <img
                          v-if="item.name == 'MLab'"
                          style="height: 25px"
                          src="https://ai.metatop.tech/img/logoColor.8e6fc19e.png"
                        />
                        {{ item.name }}
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    item: {
      type: String,
      default: "index",
    },
  },
  mounted() {
    this.navJson[this.item]["active"] = true;
  },
  data() {
    return {
      navJson: {
        index: {
          name: "首页",
          active: false,
          href: this.$assetPrefix,
        },
        aboutus: {
          name: "关于我们",
          active: false,
          href: this.$assetPrefix + "/#/aboutus",
        },
        solution: {
          name: "解决方案",
          active: false,
          href: this.$assetPrefix + "/#/solution",
        },
        services: {
          name: "产品介绍",
          active: false,
          href: this.$assetPrefix + "/#/services",
        },
        // news: {
        //   name: "公司资讯",
        //   active: false,
        //   href: this.$assetPrefix + "/#/news",
        // },
        contactus: {
          name: "加入我们",
          active: false,
          href: this.$assetPrefix + "/#/contactus",
        },
        lab: {
          name: "MLab",
          active: false,
          href: "https://ai.metatop.tech/lung-cancer",
        },
      },
    };
  },
};
</script>
