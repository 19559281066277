<template>
  <div>
    <ksnHeader item="contactus"></ksnHeader>

    <div class="page-title-area bg-8">
      <div class="container">
        <div class="page-title-content">
          <h2>加入我们</h2>
          <ul>
            <li>
              <a :href="$assetPrefix">首页</a>
            </li>
            <li class="active">加入我们</li>
          </ul>
        </div>
      </div>
    </div>

    <section class="contact-info-area bg-color-three pt-100 pb-70">
      <div class="container">
        <div class="section-title">
          <h2>用AI技术造福医术</h2>
          <p></p>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="single-contact-info">
              <i class="bx bx-location-plus"></i>
              <p>福建福州仓山区浦上大道200号2#1911</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="single-contact-info">
              <i class="bx bx-envelope"></i>
              <a href="#">
                <span class="__cf_email__">metafun@126.com</span>
              </a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
            <div class="single-contact-info">
              <i class="bx bx-phone-call"></i>
              <a href="tel:18850712420">18850712420</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="what-we-offer-area services-page-style bg-color-three pt-100 pb-70"
    >
      <div class="container">
        <div class="section-title">
          <h2>招聘岗位</h2>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 parent-content">
            <div class="single-what-we-offer post-content">
              <div class="what-we-offer-content">
                <h3>技术支持工程师</h3>
                <!-- <i class="bx bx-envelope"></i> -->
                <!-- <a href="https://www.lagou.com/gongsi/327094.html" class="read-more">投递简历</a> -->
                <p>
                  <br />
                  <b>岗位职责</b>
                  <br />根据公司要求对公司产品进行部署和运维，保证系统的持续稳定的运行
                  负责现场产品的培训、保证客户人员熟练掌握、使用产品
                  负责线上业务日常运维，7*24 小时保证线上业务的稳定运行
                  <br />
                  <b>任职要求</b>
                  <br />专科及以上学历，医疗器械、生物医学工程相关专业优先
                  两年以上影像设备实施经验，具备影像设备厂家（MR、CT、DR）或
                  PACS 厂家工作经验者优先 具有使用 Shell 脚本语言的能力能够使用
                  Python 语言进行简单的运维工作
                  学习能力强，有激情和兴趣加入创业团队一起发展能够接受出差
                  沟通能力强，具备跨部门/组织，在内外部与各类人员沟通协作的经验与能力
                  <br />
                  <b>工作地点</b>：上海、福州、合肥
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 parent-content">
            <div class="single-what-we-offer post-content">
              <div class="what-we-offer-content">
                <h3>图像算法工程师</h3>
                <!-- <i class="bx bx-envelope"></i> -->
                <!-- <a href="https://www.lagou.com/gongsi/327094.html" class="read-more">投递简历</a> -->
                <p>
                  <br />
                  <b>岗位职责</b>
                  <br />优化现有医学影像智能识别引擎中 AI
                  模型(包括算法的优化、Bug 的修复等)
                  从事医学图像的识别、分类、分割等前沿深度学习技术的研发，实现算法在产品中的落地
                  <br />
                  <b>任职要求</b>
                  <br />较强的编程能力，熟练使用 Python 等进行数据预处理，熟悉
                  Linux 环境下的开发 熟练使用至少一种深度学习框架，如
                  Keras、Tensorflow、PyTorch等 具有 2
                  年以上图像识别（基于深度学习）的相关研究基础或工作经历，具备独立开展研发工作的能力
                  发表过图像领域优秀论文，或者参加过知名 CV
                  领域有影响力的竞赛，且取得优异名次的同学优先
                  <br />
                  <b>工作地点</b>：福州
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 parent-content">
            <div class="single-what-we-offer post-content">
              <div class="what-we-offer-content">
                <h3>Python 后端开发工程师</h3>
                <!-- <i class="bx bx-envelope"></i> -->
                <!-- <a href="https://www.lagou.com/gongsi/327094.html" class="read-more">投递简历</a> -->
                <p>
                  <br />
                  <b>岗位职责</b>
                  <br />负责公司人工智能应用的需求分析、模块设计以及开发工作
                  参与核心技术问题攻关、架构设计、系统优化，并协助解决项目开发过程中的技术难题
                  配合项目的整体计划和安排，对业务人员进行技术指导
                  <br />
                  <b>任职要求</b>
                  <br />全日制本科及其以上学历，计算机相关专业
                  三到五年工作经验，精通Python语言开发
                  熟悉多种Web框架使用，如Flask、Django、Tornado等
                  熟悉数据库以及各类中间件，如MongoDB、MySQL、Redis、Kafka等
                  拥有很强的分析问题和解决问题的能力，强烈的责任感
                  拥有有良好的团队合作精神和表达沟通能力
                  学习能力强，乐于钻研和探索，关注于新技术的发展与尝试
                  了解其他语言如Java/Kotlin者优先
                  熟悉DICOM协议或有相关开发经验优先
                  <br />
                  <b>工作地点</b>：福州
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 parent-content">
            <div class="single-what-we-offer post-content">
              <div class="what-we-offer-content">
                <h3>Java 后端开发工程师</h3>
                <!-- <i class="bx bx-envelope"></i> -->
                <!-- <a href="https://www.lagou.com/gongsi/327094.html" class="read-more">投递简历</a> -->
                <p>
                  <br />
                  <b>岗位职责</b>
                  <br />负责公司人工智能云服务的需求分析、模块设计以及开发工作
                  参与核心技术问题攻关、架构设计、系统优化，并协助解决项目开发过程中的技术难题
                  配合项目的整体计划和安排，对业务人员进行技术指导
                  <br />
                  <b>任职要求</b>
                  <br />全日制本科及其以上学历，计算机相关专业
                  三到五年工作经验，具体互联网或云服务相关经验，精通Java/Kotlin语言开发
                  熟悉多种框架使用，如Spring、Hibernate等
                  熟悉数据库以及各类中间件，如MySQL、Redis、Kafka、Elasticsearch等
                  拥有很强的分析问题和解决问题的能力，强烈的责任感
                  拥有有良好的团队合作精神和表达沟通能力
                  学习能力强，乐于钻研和探索，关注于新技术的发展与尝试
                  了解其他语言如Python者优先
                  有医疗行业背景、云影像开发经验者优先
                  <br />
                  <b>工作地点</b>：福州
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 parent-content">
            <div class="single-what-we-offer post-content">
              <div class="what-we-offer-content">
                <h3>区域销售经理</h3>
                <!-- <i class="bx bx-envelope"></i> -->
                <!-- <a href="https://www.lagou.com/gongsi/327094.html" class="read-more">投递简历</a> -->
                <p>
                  <br />
                  <b>岗位职责</b>
                  <br />完成区域年度销售任务
                  经销商和重点客户管理:市场分析,招商,任务分解,回款,支持,考核。跟重点医院关系维护
                  装机医院使用情况回馈,潜力市场的产品介绍
                  公司与区域客户重点项目的协调支持
                  项目管理:AI肺癌筛查诊疗一体化项目,资金落地,各方分工协作
                  合理安排区域内装机预测,院内装机前调研,协调运维装机安排
                  <br />
                  <b>任职要求</b>
                  <br />全日制统招专科或以上学历，市场营销相关专业者优先
                  两年以上医疗行业从业经验，有医疗大数据、医疗软件、体检销售经历者优先
                  适应创业公司高强度节奏，适应经常出差
                  良好人际敏感度，较强的客户维护能力
                  具有较强的抗压能力、持续自主学习能力
                  <br />
                  <b>工作地点</b>：上海、福州、合肥
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="map-area"></div>

    <ksnFooter></ksnFooter>
  </div>
</template>

<script>
import ksnHeader from "./header";
import ksnFooter from "./footer";

export default {
  name: "Contact",
  components: { ksnHeader, ksnFooter },

  mounted() {},
  data() {
    return {
      msg: "",
    };
  },
};
</script>

<style scoped>
.post-content {
  flex: 1;
}
.parent-content {
  display: flex;
}
</style>
